import { useStaticQuery, graphql, Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import "react-input-range/lib/css/index.css"
import useOutsideClick from "../../../hooks/useOutsideClick"
import { getUser, isLoggedIn } from "../../../services/auth"

import Layout from "../../../templates/Page"
import FilterResult from "../../ContentBuilder/FilterResult"
import MainDesignFilter from "../../../templates/Includes/MainDesignFilter"
import StayInTouch from "../../../templates/Includes/StayInTouch"
import SidebarFilter from "../../../templates/Includes/SidebarFilter"

import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import { connect } from "react-redux"
import { doFilter, filterVariant } from "../../../services/filter"
import QuickViewModalData from "../../ContentBuilder/Modal/QuickViewModalData"
import { setCurrent } from "../../../state/nav"
import { shuffle } from "../../../services/ultility"
import config from "../../../config/config"
import { getBoardFromLocalStorage } from "../../../state/board"
import RecentViews from "../../RecentViews"
import { resetFilter } from "../../../state/filter"

const CabinetryList = ({ activeFilters, currentBoards, sort, dispatch }) => {
  const [items, setItems] = useState([])
  useEffect(() => {
    dispatch(setCurrent('cabinetry'))
    dispatch(getBoardFromLocalStorage())
  }, [])

  const ref = useRef()
  const pageData = {
    pageClass: "Cabinetry",
    bodyClass: "cabinetry",
  }

  const resetFilterHandler = useCallback(() => dispatch(resetFilter()), [])

  const filterEl = useMemo(() => <AllCabinets activeFilters={activeFilters} currentBoards={currentBoards} sort={sort} setItems={data => setItems(data)} resetFilter={resetFilterHandler} />, [activeFilters, currentBoards, sort])

  return (
    <Layout pageData={pageData}>
      <div className="container">
        <MainDesignFilter pageName={pageData.bodyClass} />
        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section row py-5">
          <div className="col-lg-3">
            <SidebarFilter items={items} />
          </div>
          {filterEl}
        </div>
        <RecentViews />
      </div>
      <StayInTouch />
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentBoards: state.board.all,
  sort: state.sort.current
})

export default connect(mapStateToProps, null)(CabinetryList)

const AllCabinets = ({ activeFilters, currentBoards, sort, setItems, resetFilter }) => {
  const loadPage = () => {
    setPageLimit(pageLimit + 12)
    if (pageLimit + 12 >= tiles.length) {
      setHasMore(false)
    }
  }
  const host = config.host
  const [pageLimit, setPageLimit] = useState(12)
  const [hasMore, setHasMore] = useState(true)

  const allItems = useStaticQuery(graphql`
    {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          hidePricingGlobally
        }
      }
      allSilverStripeDataObject(filter: {className: {eq: "Undigital__Objects__Cabinetry"}, UndigitalCabinetry: {status: {eq: "Active"}}}, sort: {fields: UndigitalCabinetry___objectID, order: DESC}) {
        nodes {
          UndigitalCabinetry {
            name
            showTitleOnTile
            tileBlackTitle
            description
            urlSegment
            objectID
            FeaturedVariation {
              UndigitalCabinetryVariation {
                rrPrice
                imagePosition
                objectID
                featureImagePath 
              }
            }
            Variations {
              UndigitalCabinetryVariation {
                name
                status
                rrPrice
                objectID
                featureImagePath
                Styles {
                  UndigitalProductStyle {
                    name
                    objectID
                  }
                }          
              }
            }
          }
        }
      }
    }
  `)
  const globalConfig = allItems.silverStripeDataObject.UndigitalGlobalConfig
  const [showAddBoard, setShowAddBoard] = useState(false)
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  // var sortBy = params.get("sort")
  var keyword = params.get("keyword")
  const unfiltered_items = allItems.allSilverStripeDataObject.nodes
  let _allData = []
  var i = 0
  for (; unfiltered_items[i];) {
    var _item = unfiltered_items[i].UndigitalCabinetry
    var name = _item.name
    var match = true
    if (keyword) {
      keyword = keyword.toLowerCase()
      let _name = name.toLowerCase()

      let matched_name = _name.search(keyword)

      if (matched_name === -1) {
        match = false
      }
    }
    var featuredVariation = _item.FeaturedVariation;
    _item.variantTiles = _item.Variations ? _item.Variations.map(v => {
      return {
        ...v.UndigitalCabinetryVariation,
        featured: v.objectID == featuredVariation.UndigitalCabinetryVariation.objectID
      }
    }).filter(v => v.status === 'Active').sort((i1, i2) => {
      if (i1.objectID == featuredVariation.UndigitalCabinetryVariation.objectID) return -1;
      if (i2.objectID == featuredVariation.UndigitalCabinetryVariation.objectID) return 1;
      return 0;
    }) : [];
    var FeatureImage = null;
    if (featuredVariation !== null && featuredVariation.UndigitalCabinetryVariation.featureImagePath) {
      FeatureImage = featuredVariation.UndigitalCabinetryVariation.featureImagePath;
    }
    _item.Image = FeatureImage;
    var rrp = 0;
    if (featuredVariation !== null) {
      rrp = featuredVariation.UndigitalCabinetryVariation.rrPrice
      _item.imagePosition = featuredVariation.UndigitalCabinetryVariation.imagePosition
    }
    _item.featureRRPrice = rrp;
    var itemPrice = parseFloat(
      Math.round(_item.featureRRPrice * 1000) / 1000
    ).toFixed(2);
    _item.formattedPrice =
      "$" + itemPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    _item.rrp = _item.formattedPrice
    _item.link = '/explore/cabinetry/' + _item.urlSegment;
    _item.about = _item.description
    _item.className = 'Cabinetry';

    if (_item.Variations) {
      _item.styleArray = []
      for (const i of _item.Variations) {
        const variant = i.UndigitalCabinetryVariation
        if (variant.Styles) {
          for (const st of variant.Styles) {
            const style = st.UndigitalProductStyle
            if (!_item.styleArray.some(sa => sa === style.objectID)) _item.styleArray.push(style.objectID)
          }
        }
      }
    }

    if (match) {
      _allData.push(_item)
    }
    i++
  }

  // deep copy the original tiles
  const _allTiles = JSON.parse(JSON.stringify(_allData))
  useEffect(() => {
    setItems(_allTiles)
  }, [])

  // do filter
  if (activeFilters.length) _allData = doFilter(activeFilters, _allData)

  const currentData = _allData

  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewData, setQuickViewData] = useState(null);
  const setUpQuickViewModal = useCallback((e, item, vt) => {
    e.preventDefault();
    item.vt = vt
    setQuickViewData(item);
    setShowQuickView(true);
  }, [])

  const [objectClass, setObjectClass] = useState();
  const [objectID, setObjectID] = useState();
  const [variantID, setVariantID] = useState(null);
  const setShowBoard = (className, objectID, vt) => {
    if (isLoggedIn()) {
      setObjectClass(className);
      setObjectID(objectID);
      if (vt) {
        setVariantID(vt.objectID)
      }
      setShowAddBoard(true);
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = "/login/";
      }
    }
  }

  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    let renderTiles = []
    for (const [idx, item] of currentData.entries()) {
      let isSaved = false

      if (item.variantTiles && item.variantTiles.length) {
        isSaved = false
        for (const [index, vt] of item.variantTiles.entries()) {
          for (const board of currentBoards) {
            const items = JSON.parse(board.Items)
            for (const key in items) {
              if (key.includes(item.className) && items[key].ID == item.objectID && items[key].VariantID == vt.objectID) {
                isSaved = true
              }
            }
          }
          if (activeFilters.length && !filterVariant(activeFilters, vt)) continue
          let ip = vt.rrPrice || 0;
          ip = parseFloat(
            Math.round(ip * 1000) / 1000
          ).toFixed(2)
          let formattedPrice = "$" + ip.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          let vt_image = null
          if (vt.featureImagePath) {
            vt_image = vt.featureImagePath
          }
          vt.Image = vt_image
          var _id = item.objectID + vt.objectID;
          renderTiles.push({
            objectID: item.objectID,
            id: _id,
            tilename: item.name,
            featuredRRPrice: vt.rrPrice,
            featured: vt.featured,
            item: { ...item },
            vt: { ...vt },
            tileImageSrc: vt.Image,
            formattedPrice,
            isSaved,
          })
        }
      } else {
        for (const board of currentBoards) {
          const items = JSON.parse(board.Items)
          for (const key in items) {
            if (key.includes(item.className) && items[key].ID == item.objectID) {
              isSaved = true
            }
          }
        }
        renderTiles.push({
          objectID: item.objectID,
          id: item.objectID,
          tilename: item.name,
          featuredRRPrice: item.featureRRPrice,
          featured: false,
          item: { ...item },
          vt: null,
          tileImageSrc: item.Image,
          formattedPrice: item.formattedPrice,
          isSaved,
        })
      }
    }

    renderTiles = shuffle(renderTiles)
    renderTiles.sort((i1, i2) => {
      if (i1.featured && !i2.featured) return -1;
      if (!i1.featured && i2.featured) return 1;
      return 0;
    })

    renderTiles.sort((a, b) => {
      if (sort === "az") {
        return a.tilename > b.tilename ? 1 : -1
      } else if (sort === "latest") {
        return Number(a.objectID) < Number(b.objectID) ? 1 : -1
      } else if (sort === "pricehightolow") {
        return a.featuredRRPrice < b.featuredRRPrice ? 1 : -1
      } else if (sort === "pricelowtohigh") {
        return a.featuredRRPrice > b.featuredRRPrice ? 1 : -1
      } else {
        return Number(a.objectID) < Number(b.objectID) ? 1 : -1
      }
    })

    setTiles(renderTiles)
  }, [activeFilters, currentBoards, sort])

  const renderTileGrid = useMemo(() => {
    if (tiles.length > 0 && tiles.length === tiles.slice(0, pageLimit).length) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    return tiles.length > 0 ? tiles.slice(0, pageLimit).map((tile, idx) => (
      <div key={`${tile.item.urlSegment}-${idx}`} className={`grid-item ${tile.isSaved ? 'liked' : ''} ${tile.item.showTitleOnTile == 1 ? ' showtitle' : ''} ${tile.item.tileBlackTitle == 1 ? 'text-black' : ''} ${tile.item.Image ? '' : ' no-image'}`}>
        <button
          type="button"
          className="btn-like"
          onClick={() => setShowBoard(tile.item.className, tile.item.objectID, tile.vt)}
        >
          {tile.isSaved ? (
            <>
              <svg className="clear-heart" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                <path id="Path_878" data-name="Path 878" d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
              </svg>
              <div className="clear-item"></div>
            </>
          ) : <svg id="icon-heart" width="35" height="35" viewBox="0 0 35 35"><path d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"></path></svg>}
        </button>
        <Link onClick={e => setUpQuickViewModal(e, tile.item, tile.vt)} className={`grid-item-inner ${tile.item.imagePosition}`} to={`/explore/cabinetry/${tile.item.urlSegment}/`}>
          {tile.tileImageSrc && (
            <img
              src={tile.tileImageSrc}
              alt={tile.tilename}
            />
          )}
          <div className="grid-caption">
            <h4>{tile.tilename}</h4>
            {(tile.vt && tile.vt.name) && (
            <div className="grid-bottom">
              <p className="variant-name">{tile.vt.name}</p>
            </div>
            )}
          </div>
        </Link>
      </div>
    )) : (
      <p>Awh dam! Sorry, we can't find what you're looking for. Try a different search or <a href="#" onClick={() => resetFilter()}>reset</a> your filters to continue exploring Birdy.</p>
    )
  }, [tiles, pageLimit])


  return (
    <>
      <div className="col-lg-9">
        <div className="grid">
          {renderTileGrid}
        </div>

        {tiles.length > 0 && hasMore && (
          <div className="text-center">
            <button
              onClick={loadPage}
              className="btn btn-black btn-lg mt-5"
            >
              LOAD MORE
            </button>
          </div>
        )}
      </div>
      <CreateBoardModal
        show={showAddBoard}
        handleClose={() => setShowAddBoard(false)}
        objectClass={objectClass}
        objectID={objectID}
        variantID={variantID}
      />
      {quickViewData !== null && (
        <QuickViewModalData
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
      )}
    </>
  )
}
